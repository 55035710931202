<template>
    <div class="content-wrapper">
        <PageHeader :screenName="form.nomeTecnico" :linkItems="linkItems"/>

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2 centralizar_responsivo">
            <section class="form">
                <b-form>
                    <b-row>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Nome técnico da vacina"
                            label-for="nome-tecnico-vacina"
                            label-class="label_size_vacina font-weight-bold"
                            >
                                <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                                    <div id="nome-tecnico-vacina">
                                        {{form.nomeTecnico}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Doenças relacionadas"
                            label-for="doencas-relacionadas"
                            label-class="label_size_vacina font-weight-bold"
                            v-if="doencasSelecionadas != ''"
                            >
                                <vue-perfect-scrollbar class="altura_max_doencas_scrollbar" :settings="settings">
                                    <div class="d-flex flex-wrap centralizar_responsivo"
                                    id="doencas-relacionadas">
                                        <div 
                                        v-for="doencaSelecionada in doencasSelecionadas" 
                                        :key="doencaSelecionada.nome_tecnico"
                                        class="rounded doencas"
                                        :class="doencaSelecionada.class"
                                        >
                                            {{doencaSelecionada.nome_tecnico}}
                                        </div>
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                            <b-form-group label="Doenças relacionadas" 
                            label-for="doencas-relacionadas"
                            label-class="label_size_vacina font-weight-bold"
                            v-else>
                                <span id="doencas-relacionadas">
                                    -
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Nome popular da vacina"
                            label-for="nome-popular-vacina"
                            label-class="label_size_vacina font-weight-bold"
                            v-if="form.nomePopular != null">
                                <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                                    <div id="nome-popular-vacina">
                                        {{form.nomePopular}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                            <b-form-group label="Nome popular da vacina"
                            label-for="nome-popular-vacina"
                            label-class="label_size_vacina font-weight-bold"
                            v-else>
                                <span id="nome-popular-vacina">
                                    -
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group 
                            label="Tipo de aplicação"
                            label-for="tipo-aplicacao"
                            label-class="label_size_vacina font-weight-bold">
                                <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                                    <div id="tipo-aplicacao">
                                        {{form.descricaoTipoAplicacaoSelecionado}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Descrição"
                            label-for="descricao"
                            label-class="label_size_vacina font-weight-bold">
                                <vue-perfect-scrollbar class="altura_max_textarea_scrollbar" :settings="settings">
                                    <div id="descricao">
                                        {{form.descricao}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Contraindicações"
                            label-for="contraindicacao"
                            label-class="label_size_vacina font-weight-bold">
                                <vue-perfect-scrollbar class="altura_max_textarea_scrollbar" :settings="settings">
                                    <div id="contraindicacao">
                                        {{form.contraindicacao}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Possíveis eventos adversos"
                            label-for="efeitoColateral"
                            label-class="label_size_vacina font-weight-bold">
                                <vue-perfect-scrollbar class="altura_max_textarea_scrollbar" :settings="settings">
                                    <div id="efeitoColateral">
                                        {{form.efeitoColateral}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Cuidados antes, durante e após a vacinação"
                            label-for="cuidados"
                            label-class="label_size_vacina font-weight-bold">
                                <vue-perfect-scrollbar class="altura_max_textarea_scrollbar" :settings="settings">
                                    <div id="cuidados">
                                        {{form.cuidados}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </section>
            <section v-if="canEdit" class="buttons mt-3">
                <b-button id="save-vacina" 
                    @click.prevent="redirectToEdit()"
                    variant="custom-blue"
                >
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Editar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import { getIdVacina } from '@core/utils/store/getStore';
import { setIdVacina } from '@core/utils/store/setStore';

export default {
    title: 'Detalhes vacina',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, PageHeader, VuePerfectScrollbar, ErrorModal
    },

    data() {
        return {
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Vacinas',
                    routeName: 'vacina-list'
                },
                {
                    name: 'Doença',
                    active: true
                }
            ],
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            form: {
                nomeTecnico: '',
                nomePopular: '',
                descricao: '',
                contraindicacao: '',
                efeitoColateral: '',
                cuidados: '',
                descricaoTipoAplicacaoSelecionado: ''
            },
            idDoencaShow : null,
            doencasSelecionadas: [],
            canEdit: false,
        }
    },

    mounted() {
        if((getIdVacina() === null || typeof getIdVacina() == 'undefined') && typeof this.$route.params.idVacina == "undefined") {
            return this.openModalError();
        }else if(typeof this.$route.params.idVacina != "undefined" && this.$route.params.idVacina !== getIdVacina()) {
            setIdVacina(this.$route.params.idVacina); 
        }

        this.$http.get(this.$api.vaccineParameter(getIdVacina())).then(({ data }) => {
           
            this.preenchimentoFormulario(data);
        });

        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
        
    },

    methods: {
        getArrayNomesDoencas(doencasBruto) { 
            const arrayDoencas = doencasBruto;
            for (const element of arrayDoencas) {
                const objeto = 
                    {
                        nome_tecnico: element.nome_tecnico,
                        class: "doencas"
                    }
                this.doencasSelecionadas.push(objeto);
            }
        },

        getDescricaoTipoAplicacao(idTipoAplicacao) { 
            this.$http.get(this.$api.getTiposAplicacao(), {
                params: {
                    ativo: true
                }
            })
            .then(({ data }) => {
                this.form.descricaoTipoAplicacaoSelecionado = data.find(x => x.id_tipo_aplicacao === idTipoAplicacao).descricao;
            });
        },

        preenchimentoFormulario(data){
            this.idVacinaShow  = data.id_vacina;
            this.form.nomeTecnico = data.nome_tecnico;
            this.form.nomePopular = data.nome_popular
            this.form.descricao = data.descricao;
            this.form.contraindicacao = data.contraindicacao;
            this.form.efeitoColateral =  data.efeito_colateral;
            this.form.cuidados = data.cuidados;
            this.linkItems[1].name = data.nome_tecnico;
            this.getArrayNomesDoencas(data.doencas);
            this.getDescricaoTipoAplicacao(data.id_tipo_aplicacao);
        },

        redirectToEdit() {
            this.$router.push(
                { 
                    name: 'vacina-edit', 
                    params: {
                        idVacina: getIdVacina()
                    }
                }
            );
        },

        redirecionarLista() {
            this.$router.push({ name: 'vacina-list' });
        },

        openModalError() {
            this.modalError.showModal = true;
        },
        verifyPermissions(action) {
            const permissionDN = this.$can(actions[action], subjects.VACINA_DN)

            return [permissionDN].includes(true);
        }
    }
}
</script>

<style scoped>
.doencas{
    background-color: #2772C0;
    color: white;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_textarea_scrollbar{
    max-height:113px;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_doencas_scrollbar{
    max-height:85px;
}
@media (max-width: 767px) { 
    .centralizar_responsivo{
        text-align: center;
        justify-content: center;
    }
}
</style>